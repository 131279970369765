import axios from "axios";

let API_BASE_URL = process.env.API_BASE_URL || "https://parknfly.lt:3443";


export const getGatesData = async () => {
  const response = await axios.get(`${API_BASE_URL}/gates-data`);
  return response.data;
};

export const createGateUser = async (gateUser: any) => {
  const response = await axios.post(`${API_BASE_URL}/gates-data`, gateUser);
  return response.data;
};
