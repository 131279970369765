import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/HomePage/Home';
import ReservationPage from './pages/ReservationPage/ReservationPage';
import NonExistPage from './pages/NonExistPage/NonExistPage';
import AdminLogin from './pages/AdminLogin/AdminLogin';
import AdminMenu from './pages/AdminMenu/AdminMenu';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PaymentReturn from './pages/PaymentReturn/PaymentReturn';
import PaymentNotification from './pages/PaymentNotification/PaymentNotification';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reservationPage" element={<ReservationPage />} />
          <Route path="*" element={<NonExistPage />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/menu' element={<AdminMenu />} />
          <Route path='/payment/return' element={<PaymentReturn />} /> {/* Add route for Payment Return */}
          <Route path='/payment/notify' element={<PaymentNotification />} /> {/* Add route for Payment Notification */}
          {/* Add more routes as needed */}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
