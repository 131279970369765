import axios from "axios";

let API_BASE_URL = process.env.API_BASE_URL || "https://parknfly.lt:3443";
// let API_BASE_URL = process.env.API_BASE_URL || "https://localhost:3000";


export const getPaymentMethods = async () => {
    const response = await axios.get(`${API_BASE_URL}/payment-methods`);
    return response.data;
  };
  