import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createGateUser, getGatesData } from "../api/gates";

export const useGetGatesData = () => {
    return useQuery({
        queryKey: ['gates-data'],
        queryFn: getGatesData,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
};
export const useCreateGateUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createGateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['gates-data'] });
        },
        onError: (error) => {
            console.error("Error creating gate user:", error);
        },
    });
};