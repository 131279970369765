import axios from "axios";

export interface PaymentData {
    name: string;
    email: string;
    amount: number;
    phoneNumber: string;
    occupancyDates: string[];
}

let API_BASE_URL = process.env.API_BASE_URL || "https://parknfly.lt:3443";
// let API_BASE_URL = process.env.API_BASE_URL || "https://localhost:3000";


export const createOrder = async (orderData: any) => {
    const response = await axios.post(`${API_BASE_URL}/create-order`, orderData);
    return response.data;
  };
  

  export const createPaymentUser = async (paymentUser: any) => {
    const response = await axios.post(`${API_BASE_URL}/gates-data`, paymentUser);
    return response.data;
  }