import React from "react";
import './HowToUse.css';

import parking1 from '../../images/parking1.png';
import parking2 from '../../images/parking2.jpeg';
import parking3 from '../../images/parking3.jpeg';

const HowToUse: React.FC = () => {
  return (
    <div id="howtouse">
      <div className="textCard">
        <div className="textCardText">
          <div className="header">Rezervuokite vietą automobiliui</div>
          <div className="body">
            Rezervacijos skiltyje pasirinkite automobilio palikimo ir pasiėmimo datas. Nurodę datas, spauskite „Tikrinti užimtumą“. Jei pasirinktomis dienomis aikštelėje vietų yra, jūs išvysite rezervacijos formą ir kainą už pasirinktą laikotarpį. Norėdami tęsti rezervaciją, jūs turėsite pateikti privalomus kontaktinius duomenis ir atlikti mokėjimą.          </div>
        </div>
        <div className="imageBox">
          <img src={parking1} alt="parking" />
        </div>
      </div>
      <div className="textCard">
        <div className="imageBox">
          <img src={parking2} alt="parking" />
        </div>
        <div className="textCardText">
          <div className="header">Atvykimas į aikštelę</div>
          <div className="body">
            Atvykus prie aikštelės, privažiuokite prie įvažiavimo ženklu pažymėto pakeliamo užtvaro. Privažiavę užtvarą, skambinkite telefono numeriu, nurodytu ant užtvaro (SVARBU - skambinkite iš to telefono numerio, kurį nurodėte atlikdami rezervaciją). Paskambinus užtvaras automatiškai pasikels ir jūs galėsite palikti automobilį laisvoje stovėjimo vietoje.
          </div>
        </div>
      </div>
      <div className="textCard">
        <div className="textCardText">
          <div className="header">Kaip pasiekti oro uostą?</div>
          <div className="body">
            Mūsų aikštelė yra nutolusi 800m atstumu nuo oro uosto (~11min kelio pėsčiomis). Palikę automobilį, oro uostą pasiekti galite eidami tulpių arba pievų gatvėmis. Šios gatvės yra ramios, asfaltuotos ir apšviestos.
          </div>
        </div>
        <div className="imageBox">
          <img src={parking3} alt="parking" />
        </div>
      </div>
    </div>
  );
}

export default HowToUse;
