import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { postOrderStatus } from "../api/orderStatus";

export const usePostOrderStatus = (orderId: string) => {
    const query = useQuery({
        queryKey: ['order-status'],
        queryFn: () => postOrderStatus(orderId),
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!orderId, 
    });

    useEffect(() => {
        if (query.data?.status !== "done") {
            const interval = setInterval(() => {
                query.refetch(); // Poll the API
            }, 3000); // Poll every 3 seconds

            return () => clearInterval(interval); // Clear the interval when component unmounts or status is "done"
        }
    }, [query.data?.status, query.refetch]);

    return query;
};
