import { useCreateGateUser } from "../../hooks/useGates";

const PaymentNotification = () => {
    const { mutate: createGateUser } = useCreateGateUser();

  return (
    <div>
      <h2>Payment Notification Received</h2>
      <p>Processing the payment status...</p>
    </div>
  );
};

export default PaymentNotification;
