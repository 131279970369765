import React from "react";
import Header from "../../components/Header/Header";
import HowToFind from "../../components/HowToFind/HowToFind";
import Footer from "../../components/Footer/Footer";
import HowToUse from "../../components/HowToUse/HowToUse";
import CheckOccupancy from "../../components/check-occupancy/Reservation";



const Home = () => {
    return (
        <div id="home">
            <>
                <Header />
                <CheckOccupancy />
                <HowToFind />
                <HowToUse />
                <Footer />
            </>
        </div>
    );
}

export default Home;
