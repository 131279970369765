import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';
import logo from '../../images/parkandflylogo.png';

const NavBar = () => {
  const navHeight: number = 0;
  const [isNavOpen, setNavOpen] = useState<boolean>(false);
  const navRef = useRef<HTMLUListElement>(null);
  const resizeObserverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 800) {
        setNavOpen(true);
      } else {
        setNavOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCheckboxChange = () => {
    setNavOpen(!isNavOpen);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 800) {
      setNavOpen(false);
    }
  };

  return (
    <div ref={resizeObserverRef}>
      <nav className='navContainer'>
        <Link
          to='reservation'
          className='logo'
          smooth={true}
          duration={1000}
          offset={-navHeight}
        >
          <img src={logo} alt="logo" />
        </Link>
        <input 
          type="checkbox" 
          className="menu-btn" 
          id="menu-btn" 
          onChange={handleCheckboxChange} 
          checked={isNavOpen} 
        />
        <label className='menu-icon' htmlFor='menu-btn'>
          <span className={`nav-icon ${isNavOpen ? 'open' : ''}`}></span>
        </label>
        {isNavOpen &&
          <ul className={`menu ${isNavOpen ? 'open' : ''}`} ref={navRef}>
            <li><Link to='reservation' smooth={true} duration={1000} onClick={handleLinkClick} offset={-navHeight}>Rezervacija</Link></li>
            <li><Link to='howtofind' smooth={true} duration={1000} onClick={handleLinkClick} offset={-navHeight}>Kaip mus rasti?</Link></li>
            <li><Link to='howtouse' smooth={true} duration={1000} onClick={handleLinkClick} offset={-navHeight}>Kaip naudotis?</Link></li>
          </ul>
        }
      </nav>
    </div>
  );
}

export default NavBar;
