import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput/FormInput";
// import { useCookies } from 'react-cookie';
import axios from "axios";
import { useGetAdmins } from '../../hooks/useAdmins';

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  // const [cookies, setCookie] = useCookies(['access_token']);
  const navigate = useNavigate();
  const { data: admins, error: adminsError, isLoading: adminsLoading } = useGetAdmins();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const result = await axios.post(`${process.env.API_BASE_URL}/login`, { email, password });

      if (result.data.message === "Success") {
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + 300);
        // setCookie('access_token', result.data.token, { expires: expirationDate });
        navigate('/admin/menu');
      } else {
        setError(result.data.message || "Unknown error occurred");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // Axios error
        console.error("Axios Error:", err.response?.data || err.message);
        setError(err.response?.data?.message || "An error occurred during login. Please try again.");
      } else {
        // Other errors
        console.error("Unexpected Error:", err);
        setError("An unexpected error occurred during login. Please try again.");
      }
    }
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormInput
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error">{error}</p>}
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
      {adminsLoading && <p>Loading admins...</p>}
      {adminsError && <p>Error loading admins: {adminsError.message}</p>}
      {admins && (
        <div>
          <h2>Admins List:</h2>
          <ul>
            {admins.map((admin: { id: string; name: string }) => (
              <li key={admin.id}>{admin.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;