import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { checkAvailability, createSpot, deleteSpot, getSpots } from '../api/spots';


export const useGetSpots = () => {
  return useQuery({ queryKey: ['spots'], queryFn: getSpots });
};

interface CheckAvailabilityParams {
  startDate: any;
  endDate: any;
}


export const useCheckAvailability = () => {
  return useMutation({
    mutationFn: ({ startDate, endDate }: CheckAvailabilityParams ) => checkAvailability(startDate, endDate),
  });
};

export const useCreateSpot = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSpot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spots'] });
    },
  });
};

export const useDeleteSpot = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSpot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spots'] });
    },
  });
};