// spots.js
import axios from "axios";

let API_BASE_URL = process.env.API_BASE_URL || "https://parknfly.lt:3443";
// let API_BASE_URL = process.env.API_BASE_URL || "https://localhost:3000";


export const getSpots = async () => {
  const response = await axios.get(`${API_BASE_URL}/spots`);
  return response.data;
};

export const checkAvailability = async (startDate: Date, endDate: Date) => {
  const response = await axios.post(`${API_BASE_URL}/check-availability`, {
    startDate,
    endDate,
  });
  return response.data;
};


export const createSpot = async (project: any) => {
  const response = await axios.post(`${API_BASE_URL}/spots`, project);
  return response.data;
};

export const deleteSpot = async (id: string) => {
  const response = await axios.delete(`${API_BASE_URL}/spots/${id}`);
  return response.data;
};

export const createOccupancy = async (spotid: string, occupancy: any) => {
  const response = await axios.put(
    `${API_BASE_URL}/spots/${spotid}`,
    occupancy
  );
  return response.data;
};

export const deleteOccupancy = async (spotId: string, occupancyId: string) => {
  const response = await axios.delete(`${API_BASE_URL}/spots/${spotId}/occupancies/${occupancyId}`);
  return response.data;
};
