import React from "react";



const NonExistPage: React.FC = () => {
  return (
    <div id="reservationPage">
        <h1>404</h1>
    </div>
  );
}

export default NonExistPage;
