import React, { useEffect, useState } from "react";
import { useCreateOrder, useGetPaymentMethods } from "../../hooks/useGetPaymentMethods";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import "./PaymentMethodsModal.css"; // Import the styles
import { useCheckAvailability } from "../../hooks/useSpots";

interface PaymentMethodsModalProps {
  formDataProp: {
    name: string;
    carPlate: string;
    pricePerDay: number;
    mobileNumber: string;
    email: string;
    agreeWithRules: boolean;
    parkingDate: any;
    totalPrice: number;
    companyName?: string | null;
    companyCode?: string | null;
    taxesCode?: string | null;
    companyAddress?: string | null;
    isInvoiceChecked?: boolean;
  };
  emptySpot: string;
  onClose: () => void;
}

enum Countries {
  LT = "Lithuania",
  LV = "Latvia",
  EE = "Estonia",
  PL = "Poland",
  FI = "Finland",
  DE = "Germany",
}

interface PaymentMethod {
  code: string;
  logoUrl: string;
  name: string;
  supportedCurrencies: string[];
  uiPosition: number;
}

const setTimeTo21Minus1Day = (dateStr: string) => {
  const date = new Date(dateStr);
  // Subtract 1 day from the date
  date.setDate(date.getDate() - 1);
  // Set the hours, minutes, seconds, and milliseconds to 21:00:00.000
  date.setUTCHours(21, 0, 0, 0);
  return date.toISOString(); // Convert back to ISO string format
};


const countriesOptions = Object.keys(Countries).map((key) => ({
  label: Countries[key as keyof typeof Countries],
  value: key,
}));

const PaymentMethodsModal: React.FC<PaymentMethodsModalProps> = ({
  onClose,
  formDataProp,
  emptySpot,
}) => {
  const { data: paymentMethodsData } = useGetPaymentMethods();
  const [selectedCountry, setSelectedCountry] = useState<string>("LT");
  const [isAvailableSpots, setIsAvailableSpots] = useState<boolean>(true);
  const [currentPaymentMethods, setCurrentPaymentMethods] = useState<PaymentMethod[]>([]);
  
  const { mutate: createOrder } = useCreateOrder();
  const { mutate: checkAvailability } = useCheckAvailability();

  useEffect(() => {
    if (paymentMethodsData && paymentMethodsData.paymentInitiation) {
      const methods = paymentMethodsData.paymentInitiation.setup[selectedCountry]?.paymentMethods || [];
      setCurrentPaymentMethods(methods);
    }
  }, [paymentMethodsData, selectedCountry]);

  const handleConfirm = (method: PaymentMethod) => {
    if (!method) return;

    const selectedStartDate = setTimeTo21Minus1Day(formDataProp.parkingDate[0].startDate);
    const selectedEndDate = setTimeTo21Minus1Day(formDataProp.parkingDate[0].endDate);
    // Use the checkAvailability hook
    checkAvailability({ startDate: selectedStartDate, endDate: selectedEndDate }, {
      onSuccess: (result) => {
        if (result.success) {
          // Update the emptySpot if necessary
          const spotId = result.spotId === emptySpot ? emptySpot : result.spotId;

          const orderData = {
            ...formDataProp,
            grandTotal: formDataProp.totalPrice,
            currency: "EUR",
            locale: selectedCountry,
            preferredProvider: method.code,
            phoneNumber: formDataProp.mobileNumber,
            fromDate: setTimeTo21Minus1Day(formDataProp.parkingDate[0].startDate),
            endDate: setTimeTo21Minus1Day(formDataProp.parkingDate[0].endDate),
            email: formDataProp.email,
            name: formDataProp.name,
            pricePerDay: formDataProp.pricePerDay,
            carPlate: formDataProp.carPlate,
            emptySpot: spotId,
            isInvoiceChecked: formDataProp.isInvoiceChecked,
            companyName: formDataProp.companyName || null,
            companyCode: formDataProp.companyCode || null,
            taxesCode: formDataProp.taxesCode || null,
            companyAddress: formDataProp.companyAddress || null,
          };

          // Create the order and redirect to the payment URL
          createOrder(orderData, {
            onSuccess: (data) => {
              window.location.href = data.paymentUrl;
            },
            onError: (error) => {
              console.error("Failed to create order", error);
            },
          });
        } else {
          // Spot is no longer available
          setIsAvailableSpots(false);
        }
      },
      onError: (error) => {
        console.error("Error checking availability:", error);
        setIsAvailableSpots(false);
      },
    });
  };

  return (
    <Dialog
      visible={true}
      onHide={onClose}
      breakpoints={{ "960px": "75vw", "640px": "90vw" }}
      style={{ width: "50vw" }}
      header="Pasirinkite banką"
      className="payment-methods-modal"
    >
      <div className="payment-methods-container">
        <Dropdown
          className="country-dropdown"
          options={countriesOptions}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.value)}
          placeholder="Pasirinkite šalį"
        />

        <div className="payment-methods-list">
          {isAvailableSpots ? (
            currentPaymentMethods.length > 0 ? (
              currentPaymentMethods.map((method) => (
                <div
                  key={method.code}
                  className="payment-method-card"
                  onClick={() => handleConfirm(method)}
                >
                  <img
                    src={method.logoUrl}
                    alt={method.name}
                    className="payment-method-logo"
                  />
                </div>
              ))
            ) : (
              <div className="no-methods">No payment methods available for this country.</div>
            )
          ) : (
            <div>Apgailestaujame, bet vietų pasirinktomis dienomis nėra</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentMethodsModal;
