import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getPaymentMethods } from "../api/paymentMethods";
import { createOrder, createPaymentUser } from "../api/pay";

export const useGetPaymentMethods = () => {
    return useQuery({ queryKey: ['payment-methods'], queryFn: getPaymentMethods });
};

  export const useCreateOrder = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createOrder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order'] });
        },
        onError: (error) => {
            console.error("Error creating gate user:", error);
        },
    });
};

export const useCreatePaymentUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createPaymentUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payment-user'] });
        },
        onError: (error) => {
            console.error("Error creating gate user:", error);
        },
    });
};
