import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useGetSpots, useCreateSpot, useDeleteSpot } from "../../hooks/useSpots";
import { useGetCustomers, useDeleteCustomer } from "../../hooks/useCustomers";
import { format, isWithinInterval, eachDayOfInterval } from 'date-fns';
import { DateRange } from 'react-date-range';
import './AdminMenu.css';
import lt from 'date-fns/locale/lt';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface Occupancy {
  startDate: string;
  endDate: string;
}

interface Spot {
  _id: string;
  spotNr: string;
  occupancies: Occupancy[];
}

interface Customer {
  _id: string;
  spotId: string;
  name: string;
  carPlate: string;
  mobileNumber: string;
  email: string;
  price: string;
  occupancy: Occupancy[];
}

const AdminMenu: React.FC = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["access_token"]);
  const { data: spots, isLoading: spotsLoading } = useGetSpots();
  const { data: customers, isLoading: customersLoading } = useGetCustomers();
  const createSpotMutation = useCreateSpot();
  const deleteSpotMutation = useDeleteSpot();
  // const createCustomerMutation = useCreateCustomer();
  const deleteCustomerMutation = useDeleteCustomer();
  const [isSpotsOpen, setSpotsOpen] = useState(false);
  const [isCustomersOpen, setCustomersOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [availableSpotCount, setAvailableSpotCount] = useState<number>(0);
  const [parkingDate, setParkingDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    } as any
  ]);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = cookies.access_token;
        if (token) {
          setIsLoggedIn(true);
        } else {
          navigate("/admin/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        navigate("/admin/login");
      }
    };
    checkToken();
  }, [cookies, navigate]);

  if (!isLoggedIn) {
    return null;
  }

  const checkOccupanciesAccordanceToDate = () => {
    const selectedStartDate: Date = parkingDate[0].startDate;
    const selectedEndDate: Date = parkingDate[0].endDate;


    const count = spots?.reduce((count: number, spot: Spot) => {
      const isOccupied = spot.occupancies.some(({ startDate, endDate }: Occupancy) => {
        const spotStartDate = new Date(startDate);
        const spotEndDate = new Date(endDate);

        return isWithinInterval(selectedStartDate, { start: spotStartDate, end: spotEndDate }) ||
          isWithinInterval(selectedEndDate, { start: spotStartDate, end: spotEndDate });
      });


      if (!isOccupied) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

    setAvailableSpotCount(count || 0);
  };

  const handleLogOut = () => {
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + 1);
    setCookie('access_token', "", { expires: expirationDate });
    setIsLoggedIn(false);
    navigate("/");
  };

  const createNewSpot = async () => {
    try {
      createSpotMutation.mutate({ occupancies: [] });
    } catch (error) {
      console.error(error);
    }
  };

  const spotDelete = async (spotId: string) => {
    try {
      deleteSpotMutation.mutate(spotId);
    } catch (error) {
      console.error(error);
    }
  };

  const availableSpotsForDate = (date: Date) => {
    return spots?.filter((spot: Spot) => !spot.occupancies.some(({ startDate, endDate }: Occupancy) =>
      isWithinInterval(date, { start: new Date(startDate), end: new Date(endDate) })
    )).length || 0;
  };

  const renderDateFreeSpots = () => {
    const days = eachDayOfInterval({ start: new Date(), end: new Date(new Date().setMonth(new Date().getMonth() + 1)) });
    return days.map((day, index) => (
      <div key={index}>
        {format(day, "yyyy-MM-dd")}: {availableSpotsForDate(day)} free spots
      </div>
    ));
  };

  return (
    <div>
      <h1>Admin Menu</h1>
      <Button label="Logout" icon="pi pi-sign-out" onClick={handleLogOut} />
      <div>Total current active parking spots: {spots?.length}</div>

      <Button label="View All Spots" icon="pi pi-list" onClick={() => setSpotsOpen(true)} />
      <Dialog visible={isSpotsOpen} onHide={() => setSpotsOpen(false)} header="Parking Spots">
        <DataTable value={spots} paginator rows={10} loading={spotsLoading}>
          <Column field="_id" header="Spot ID" />
          <Column field="spotNr" header="Spot Number" />
          <Column field="occupancies" header="Occupancies" body={(rowData: Spot) => (
            <ul>
              {rowData.occupancies.map((occupancy, index) => (
                <li key={index}>
                  {format(new Date(occupancy.startDate), "yyyy-MM-dd")} to {format(new Date(occupancy.endDate), "yyyy-MM-dd")}
                </li>
              ))}
            </ul>
          )} />
          <Column body={(rowData: Spot) => (
            <Button label="Delete" icon="pi pi-trash" onClick={() => spotDelete(rowData._id)} />
          )} />
        </DataTable>
        <Button label="Add New Spot" icon="pi pi-plus" onClick={createNewSpot} />
      </Dialog>

      <Button label="View All Customers" icon="pi pi-users" onClick={() => setCustomersOpen(true)} />
      <Dialog visible={isCustomersOpen} onHide={() => setCustomersOpen(false)} header="Customers">
        <DataTable value={customers} paginator rows={10} loading={customersLoading}>
          <Column field="_id" header="Customer ID" />
          <Column field="name" header="Name" />
          <Column field="carPlate" header="Car Plate" />
          <Column field="mobileNumber" header="Mobile Number" />
          <Column field="email" header="Email" />
          <Column field="price" header="Price" />
          <Column body={(rowData: Customer) => (
            <Button label="Delete" icon="pi pi-trash" onClick={() => deleteCustomerMutation.mutate(rowData._id)} />
          )} />
        </DataTable>
      </Dialog>

      <Button label="Check Free Spots for Dates" icon="pi pi-calendar" onClick={() => setOpenDate(!openDate)} />
      <div className="dateRangeBox">
        {openDate && (
          <DateRange
            editableDateInputs={true}
            onChange={item => setParkingDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={parkingDate}
            className="dateInput"
            locale={lt}
          />
        )}
      </div>

      <div>
        <div>
          <div>Arrival Date</div>
          <div>{`${format(parkingDate[0].startDate, "yyyy-MM-dd")}`}</div>
        </div>
        <div>
          <div>Departure Date</div>
          <div>{`${format(parkingDate[0].endDate, "yyyy-MM-dd")}`}</div>
        </div>
      </div>
      <Button label="Check Free Spots Count" icon="pi pi-search" onClick={checkOccupanciesAccordanceToDate} />
      <div>Free spots count for selected dates: {availableSpotCount}</div>

      <h2>Future Occupancies</h2>
      <div>{renderDateFreeSpots()}</div>
    </div>
  );
};

export default AdminMenu;