import React from "react";
import NavBar from "../NavBar/NavBar";


const Header = () => {
  return (
    <div id="header">
        <NavBar/>
    </div>
  );
}

export default Header;
