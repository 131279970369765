import axios from "axios";

let API_BASE_URL = process.env.API_BASE_URL || "https://parknfly.lt:3443";


export const getCustomers = async () => {
  const response = await axios.get(`${API_BASE_URL}/customers`);
  return response.data;
};

export const getCustomer = async (id: string) => {
  const response = await axios.get(`${API_BASE_URL}/customers/${id}`);
  return response.data;
};

export const createCustomer = async (project: any) => {
  const response = await axios.post(`${API_BASE_URL}/customers`, project);
  return response.data;
};

export const updateCustomer = async (project: any) => {
  const response = await axios.put(
    `${API_BASE_URL}/customers/edit/${project.id}`,
    project
  );
  return response.data;
};

export const deleteCustomer = async (id: string) => {
  const response = await axios.delete(`${API_BASE_URL}/customers/${id}`);
  return response.data;
};
